import {StyleSheet, View, TouchableOpacity, Image} from 'react-native';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
import React from 'react';
import theme from '@/style';
import GameInfo from './game-info';
import GameResult from './game-result';
import Question from '@/components/svgs/basic/question';
import Text from '@/components/basic/text';
import Analyze from '@/components/svgs/basic/analyze';
import {downIcon} from '@/common-pages/rebate/rebate.variables';
import {LotteryItemInfo, LotteryMode} from '../mix-lottery-service';
import {LotteryTimeCutdownProps} from '@/components/business/games/lottery-time-cutdown';

const LotteryHeader = ({
  lotteryInfo,
  modeId,
  showRule,
  goAnalyze,
  timeVersion = 0,
  cycle,
  isQuick,
  isLive,
  ...props
}: {
  lotteryInfo?: LotteryItemInfo;
  showRule?: () => void;
  goAnalyze?: () => void;
  modeId?: `${LotteryMode}`;
  cycle?: number;
  isQuick?: boolean;
  timeVersion?: number;
  isLive?: boolean;
} & LotteryTimeCutdownProps) => {
  return (
    <LinearGradient colors={['#B5AEAE', '#CFC7C7']} style={[theme.padding.l]}>
      <GameInfo
        gameLogo={lotteryInfo?.icon}
        lotteryName={lotteryInfo?.lotteryName}
        issueNum={lotteryInfo?.roundNo}
        remainingTime={lotteryInfo?.lessSec}
        timeVersion={timeVersion}
        cycle={cycle}
        isQuick={isQuick}
        isLive={isLive}
        {...props}
      />
      <View style={styles.crossLine} />
      <View style={[theme.flex.row]}>
        <GameResult
          modeId={modeId}
          resultList={
            lotteryInfo?.analyzeData?.[0] ||
            lotteryInfo?.results || ['------', '------']
          }
          isLive={isLive}
        />
        <View style={[theme.flex.flex1, theme.flex.centerByCol]}>
          <View style={styles.verticalLine} />
        </View>
        <View style={[theme.flex.centerByRow]}>
          <TouchableOpacity
            onPress={showRule}
            activeOpacity={0.8}
            style={[theme.flex.row, theme.flex.centerByCol]}>
            <Question />
            <Text fontWeight="500" style={[theme.margin.leftxxs]}>
              Rules
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={goAnalyze}
            activeOpacity={0.8}
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.background.white,
              theme.borderRadius.xs,
              theme.flex.between,
              theme.padding.tbxxs,
              theme.padding.lrxs,
              theme.margin.topxxl,
            ]}>
            <Analyze />
            <Image style={[styles.iconSize]} source={downIcon} />
          </TouchableOpacity>
        </View>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  crossLine: {
    height: 1,
    backgroundColor: '#9E9696',
    marginVertical: 8,
  },
  verticalLine: {
    width: 1,
    flex: 1,
    backgroundColor: '#9E9696',
  },
  iconSize: {
    width: 14,
    height: 14,
  },
});

export default LotteryHeader;
