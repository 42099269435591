import {View, StyleSheet, TextStyle} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {COLORS} from '../constant';
import {Size} from '@/types';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

export interface DigitBallProps {
  digit: string;
  type: 'A' | 'B' | 'C' | 'D' | 'UNUSED';
  size?: number;
  disabled?: boolean;
  bg?: string;
  isPrimary?: boolean;
  hasBorder?: boolean;
  borderColor?: string;
  fontSize?: Size;
  fontWeight?: TextStyle['fontWeight'];
}

const DigitBall = (props: DigitBallProps) => {
  const {calcActualSize} = useScreenSize();
  const styles = StyleSheet.create({
    border: {
      borderWidth: calcActualSize(2),
    },
  });
  const {
    digit,
    type,
    size = 20,
    disabled = false,
    bg,
    isPrimary,
    hasBorder = false,
    borderColor,
    fontSize = 'medium',
    fontWeight = '700',
  } = props;

  return (
    <View
      style={[
        theme.flex.center,
        {
          width: calcActualSize(size),
          height: calcActualSize(size),
          borderRadius: calcActualSize(size / 2),
          backgroundColor: isPrimary
            ? theme.basicColor.primary
            : bg
            ? bg
            : COLORS[type],
        },
        hasBorder && styles.border,
        hasBorder && {
          borderColor,
        },
        // eslint-disable-next-line react-native/no-inline-styles
        disabled && {opacity: 0.3},
      ]}>
      <Text
        calc
        color={theme.basicColor.white}
        size={fontSize}
        fontWeight={fontWeight}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{includeFontPadding: false}}>
        {digit}
      </Text>
    </View>
  );
};

export default DigitBall;
