import {TouchableOpacity, View, ScrollView, Image} from 'react-native';
import {LotteryModeItem, getLottoList} from './mix-lottery-service';
import React from 'react';
import LazyImage from '@/components/basic/image';
import Text from '@/components/basic/text';
import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {getHMSbyTimer} from '@/utils';
import {BasicObject} from '@/types';
import CheckIcon from '@/components/svgs/basic/checked';

export interface LotteryPickerProps {
  lotteryID?: number;
  onClose?: () => void;
  isOpen?: boolean;
  onItemSelect?: (id: number, isQuick?: string) => void;
  isQuick?: boolean;
}

const LotteryPicker = ({
  isOpen,
  onClose,
  lotteryID,
  onItemSelect,
}: LotteryPickerProps) => {
  const [list, setList] = React.useState<LotteryModeItem[]>([]);
  const [quickList, setQuickList] = React.useState<LotteryModeItem[]>([]);
  const timer = React.useRef(new Date().getTime());
  const updateList = async () => {
    try {
      const res = await getLottoList();
      setList(res);
    } finally {
    }
  };

  const updateQuick = async () => {
    try {
      const res = await getLottoList(true);
      setQuickList(res);
    } finally {
    }
  };

  React.useEffect(() => {
    updateList();
    updateQuick();
  }, []);
  React.useEffect(() => {
    if (isOpen) {
      updateList();
      updateQuick();
    }
  }, [isOpen]);

  const targetList = React.useMemo(() => {
    const target = list;
    const open = target.filter(item => item.lessSec > 0);
    const close = target.filter(item => item.lessSec <= 0);
    return (quickList || []).concat(
      open.sort((a, b) => a.lessSec - b.lessSec).concat(close),
    );
  }, [list, quickList]);

  return (
    <View style={[]}>
      <View style={[theme.flex.row, theme.flex.between, theme.padding.l]}>
        <Text size="medium" fontFamily="fontInterBold">
          Please Choose
        </Text>
        <NativeTouchableOpacity onPress={() => onClose?.()}>
          <Image
            style={[theme.icon.m]}
            source={require('@components/assets/icons/close.webp')}
          />
        </NativeTouchableOpacity>
      </View>
      <ScrollView contentContainerStyle={[theme.padding.lrl]}>
        {targetList.map((item, _i) => (
          <Item
            onPress={() =>
              onItemSelect?.(item.lotteryID, item.isQuick ? '1' : '0')
            }
            info={item}
            key={_i}
            isQuick={item.isQuick}
            timer={timer.current}
            isSelected={lotteryID === item.lotteryID}
          />
        ))}
      </ScrollView>
    </View>
  );
};

const Item = ({
  info,
  timer,
  isSelected,
  onPress,
  isQuick,
}: {
  info: LotteryModeItem;
  timer: number;
  isSelected?: boolean;
  isQuick?: boolean;
  onPress?: () => void;
}) => {
  const isClosed = React.useMemo(() => {
    return isQuick ? false : info.lessSec <= 0;
  }, [info.lessSec, isQuick]);

  const times = React.useMemo<BasicObject>(() => {
    if (info.lessSec > 0) {
      const duration =
        info.lessSec - Math.round((new Date().getTime() - timer) / 1000);
      const {seconds, minutes, hours, days} = getHMSbyTimer(duration * 1000);
      return {
        seconds,
        minutes,
        hours,
        days,
      };
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.lessSec]);

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={isClosed}
      activeOpacity={0.8}
      style={[
        theme.flex.row,
        theme.flex.between,
        theme.flex.centerByCol,
        {height: theme.imageSize.s},
        // eslint-disable-next-line react-native/no-inline-styles
        isClosed && {opacity: 0.3},
      ]}>
      <View style={[theme.flex.row, theme.flex.centerByCol]}>
        <LazyImage
          imageUrl={info.icon}
          width={28}
          radius={14}
          height={28}
          occupancy="transparent"
        />
        <Text
          style={[theme.margin.lefts]}
          fontWeight="500"
          size="medium"
          color={theme.basicColor.dark}>
          {info.lotteryName}
        </Text>
      </View>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          {gap: theme.paddingSize.s},
        ]}>
        {isClosed && (
          <Text fontFamily="fontInterBold" size="medium">
            Closed
          </Text>
        )}
        {!isClosed && !isQuick && (
          <View style={[theme.flex.alignEnd]}>
            {times.days > 0 && (
              <Text fontFamily="fontInterBold" size="medium">
                {times.days} Day
              </Text>
            )}
            <Text fontFamily="fontInterBold" size="medium">
              {times.hours < 10 ? `0${times.hours}` : times.hours}:
              {times.minutes < 10 ? `0${times.minutes}` : times.minutes}:
              {times.seconds < 10 ? `0${times.seconds}` : times.seconds}
            </Text>
          </View>
        )}
        {isSelected && <CheckIcon />}
      </View>
    </TouchableOpacity>
  );
};

export default LotteryPicker;
